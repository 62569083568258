import React from 'react';
import Calculator from './components/Calculator';

import './App.scss';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <span className="app-logo serif">Asset Lab Facts</span>
      </header>
      <main className="app-content">
        <div className="container-fluid">
          <Calculator />
        </div>
      </main>
      <footer className="app-footer">&copy; 2020 Desert Software Solutions</footer>
    </div>
  );
}

export default App;
